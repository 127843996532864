const theme = {
  font: {
    primary: `'Overpass', serif`,
    secondary: `'Overpass', serif`,
  },
  font_size: {
    small: 'font-size: 16px; line-height: 28px',
    regular: 'font-size: 20px; line-height: 35px',
    large: 'font-size: 30px; line-height: 40px',
    larger: 'font-size: 46px; line-height: 52px',
    xlarge: 'font-size: 64px; line-height: 72px',
  },
  color: {
    white: {
      regular: '#FFFFFF',
      dark: '#F6F6F6',
    },
    black: {
      lighter: '#ABA8AF',
      light: '#564F62',
      regular: '#211E26',
    },
    primary: '#211E26',
    ls : {
      whiteC: 'rgba(253, 253, 253, 0.69)',
      white: '#FDFDFD',
      sWhite: '#FFFFFF',
      black: '#1E1E1E',
      col1: '#F6F8F9',
      col2: '#F6F8F9',
      shadow: '#D1D9E6',
      shadow1: '#f9f9f9',
      shadow11: 'rgba(209, 217, 230, .5);',
      shadow2: 'rgba(209, 217, 230, .1);',
      acWhite: 'E4E4E4',
    },
    pv : {
      text: '#000',
      invText: '#fff',
      button: '#393939',
      box: '#F2F2F2',
      black: '#000',
      white: '#FFF',
      col1: '#F6F8F9',
      col2: '#F6F8F9',
      lightAccent: '#F2F2F2',
      darkAccent: '#393939',
      shadow: 'rgb(0,0,0,.25)',
      header: 'rgb(0,0,0,.87)',
      bg: '#fff',
    }
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
  },
};

export default theme;
