import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: ${props => props.theme.screen.xs}) {
    max-width: 540px;
  }

  @media (min-width: ${props => props.theme.screen.sm}) {
    max-width: 720px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    max-width: 960px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    max-width: 1200px;
  }

  ${props =>
    props.fluid &&
    `
    max-width: 1200px !important;
  `};
`;

export const Section = styled.section`
  margin-bottom: -400px;
  background-color: ${props => props.theme.color.ls.white};
  padding: 128px 0;
  overflow: hidden;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 96px 0;
  }

    ${props =>
      props.accent &&
      `background: linear-gradient(90deg, ${
        props.accent === 'secondary'
          ? props.theme.color.ls.col1
          : props.theme.color.ls.col2  
      } 0%, ${
        props.accent === 'secondary'
          ? props.theme.color.ls.col2
          : props.theme.color.ls.col1 
      } 100%);       `};
`;
